<template lang="html">
  <section class="novetie" v-if="dataContent">
    <router-link :to="{
                        name: 'page-entry',
                        params: {
                          typeContent:'novedad',
                          slug: dataContent.slug,
                        },
                      } " class="content-new -novedad" title="Enlace a la noticia">
      <div class="info-box">
        <h2 class="title">
          {{dataContent.name}}
        </h2>
        <!-- <p class="dest" v-if="dataContent.seo_description">{{ CutText(dataContent.seo_description , 100) }}</p> -->
        <p class="box-dates">
          <span class="date-text p-left-0-px">{{ currentDateTime(dataContent.publish_date) }}</span>
          <span class="author m-left-10-px p-left-0-px" v-if="dataContent.source">{{dataContent.source}}</span>
        </p>

        <p class="box-dates">
          <template v-for="theme in dataContent.themes" :key="theme.id">
            <span class="category-tag m-right-10-px">{{ theme.name }}</span>
          </template>
          <span class="category-location" v-if="dataContent.zone">{{
            dataContent.zone.name
            }}</span>
        </p>
      </div>
    </router-link>
  </section>

</template>

<script lang="js">
  import moment from 'moment'
  export default {
    name: 'novetie',
    props: ['dataContent'],
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {
      currentDateTime(date) {
        return moment(date).locale('es').format('L')
      },
      CutText(texto, limite) {
        var puntosSuspensivos = "...";
        if (texto.length > limite) {
          texto = texto.substring(0, limite) + puntosSuspensivos;
        }

        return texto;
      }
    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">
    @import "@/styles/general.scss";
  .content-new.-novedad {
    text-decoration: none;
    color: initial;
    border-bottom: 1px solid $blue;
    display: inline-block;
    width: calc(100% - 30px);
    padding: 15px 0;
    margin-bottom: 16px;
  }

  .novetie:last-of-type .content-new.-novedad {
    border: 0;
  }
</style>